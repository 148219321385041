<!-- eslint-disable vue/no-v-html -->
<template lang="pug">
div(
  v-if='group?.product'
  data-cnstrc-product-detail
  :data-cnstrc-item-id='group.product.productLineSlug'
  :data-cnstrc-item-variation-id='group.product.skuSlug ? `${group.product.productLineSlug}_${group.product.skuSlug}` : undefined'
  :data-cnstrc-item-name='group.title'
  :data-cnstrc-item-price='group.product.actualPrice?.min'
  class='my-6'
)
  div(class='flex gap-x-3')
    div(class='min-w-[126px]')
      a(@click='loadLightbox()')
        img(
          v-if='group.product.gallery[0].key'
          :src='getImageUrl(group.product.gallery[0].key, group.product.gallery[0].filename, 126, 94, 70, "fff")'
          :alt='group.product.gallery[0].title'
          width='126'
          height='94'
          class='m-auto md:w-full'
          loading='lazy'
        )
    div
      div(class='space-y-1')
        h2(class='uppercase m-0 text-base' v-html='group.title')
        div(class='flex flex-wrap')
          ProductPricing(
            v-if='!$sitewideConfig.config.nonTransactionalEnabled'
            :actual-price='group.product.actualPrice'
            :base-price='group.product.basePrice'
            :discount='group.product.discount'
            :is-dealer-only='group.product.isDealerOnly || isDealerOnly'
            :small-price-text='true'
            class='min-w-20 pr-2'
          )
          div(v-if='group.product.sku' class='text-gray text-right text-sm mt-1 whitespace-nowrap')
            | Part # {{ group.product.sku }}

        div(
          v-if='$sitewideConfig.config.sameDayShippingEnabled && group.product.shipping?.sameDay'
          class='text-[#2C8560] font-bold'
        ) {{ shippingText }}
        ProductDrill(
          v-for='drill in group.drills'
          :key='`${drill.type + drill.groupKey}`'
          :type='drill.type'
          :group-key='drill.groupKey'
        )

        div(
          v-if='group.product.hasOverriddenInstallGuide && group.product.installation && group.product.installation.files && group.product.installation.files.length <= 2'
          class='pb-2'
        )
          div(v-for='file in group.product.installation.files' :key='file.filename' class='flex items-center')
            img(src='/images/file-pdf.svg' alt='pdf' width='16' height='16' style='width: 16px; height: 16px' class='mr-2')
            a(
              v-if='file.folder'
              :href='getFileUrl(file.folder, file.filename)'
              target='_blank'
              class='inline-block underline text-black flex-auto'
            ) Install Guide

        BaseFormCheckbox(
          v-if='group?.product.sku && !$sitewideConfig.config.nonTransactionalEnabled'
          :name='group.product.sku'
          label='Select'
          :classes='{ label: "font-bold" }'
        )
</template>
<script setup lang="ts">
const ocStore = useOrderControlStore()
const { $sitewideConfig } = useNuxtApp()
const { getImageUrl } = useUrls()
const { showLightbox } = usePhotoswipeLightbox()
const { sameDayShippingText } = useSameDayShippingDisplay()
const props = defineProps<{
  groupKey: string
  qtySelector: ProductLine['qtySelector']
  isDealerOnly: ProductLine['isDealerOnly']
}>()
const group = ocStore.getGroup(props.groupKey)

const shippingText = computed(() => {
  if (!group?.product?.shipping) return ''
  if ($sitewideConfig.config.sameDayShippingEnabled && group.product.shipping.sameDay)
    return `${sameDayShippingText} Ready`

  return getFreeShippingText(group.product.shipping)
})

function loadLightbox() {
  if (!group?.product?.gallery[0]?.key) return

  const item: PhotoswipeItem = {
    src: getImageUrl(group.product.gallery[0].key, group.product.gallery[0].filename, 1024, 768, 70, 'fff') || '',
    width: 1024,
    height: 768,
    caption: group.product.gallery[0].title,
  }
  showLightbox([item], 0)
}
</script>
