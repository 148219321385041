// sorts can array of objects by a property taking into account weirdo number combos
export default function (target: Record<string, any>[], property: string) {
  // Does the first real option have a number?  (skip the first entry since it may be a label like 'Select Year').. Yes doesn't make sense for facets but meh.
  if (target.length > 1 && /\d+/.test(target[1][property as keyof (typeof target)[1]])) {
    target.sort((a, b) => {
      const aVal = a[property as keyof typeof a]
      const bVal = b[property as keyof typeof b]
      // if option text is a number (ie year dropdown) then just do a normal sort compare in descending order -- N/A Years are now text
      if (typeof aVal === 'number' && typeof bVal === 'number') return bVal - aVal
      // if its a string with numbers then run function to pull out the numbers so we can sort them
      const numA = getNumberGroups(aVal)
      const numB = getNumberGroups(bVal)
      // if first set of numbers is equal then we get a 0 which is falsey then sorts by second and third set of numbers if available
      return numA[0] - numB[0] || numA[1] - numB[1] || numA[2] - numB[2]
    })
  } else {
    target.sort((a, b) => {
      const aVal = a[property as keyof typeof a]
      const bVal = b[property as keyof typeof b]

      if (aVal > bVal) return 1
      if (aVal < bVal) return -1
      return 0
    })
  }
}

/**
 * Gets groups of numbers from a string
 * @param str A string to parse for groups of numbers
 * @returns an array of numbers for comparison
 * @example 17&quot; X 8&quot;  => [17000, 8000] https://realtruck.com/p/moto-metal-gloss-black-mo970-wheels/v/chevy/silverado-1500/2023/
 * @example 3' 10" => [3000, 10000]
 */
function getNumberGroups(str: string) {
  const words = str.split(' ')
  const numbers: number[] = []

  words.forEach((word) => {
    // if numbers represent a length (ie 3'10") then replace single quote with period and push to numbers array
    if (word.match(/\d+' *\d+(?=")/) !== null) {
      numbers.push(parseFloat(word.replace(/ *'/, '.')) * 1000)
    } else {
      // remove any character that is not a number or period and push to numbers array
      const nbr = parseFloat(word.replace(/[^.\d]/g, '')) * 1000
      if (nbr) numbers.push(nbr)
    }
  })
  return numbers
}
