<!-- eslint-disable vue/no-v-html -->
<template lang="pug">
div(
  v-if='group?.product'
  data-cnstrc-product-detail
  :data-cnstrc-item-id='group.product.productLineSlug'
  :data-cnstrc-item-variation-id='group.product.skuSlug ? `${group.product.productLineSlug}_${group.product.skuSlug}` : undefined'
  :data-cnstrc-item-name='group.title'
  :data-cnstrc-item-price='group.product.actualPrice?.min'
  class='my-16'
)
  div(class='grid mx-auto gap-x-6 md:grid-rows-[auto,1fr] md:grid-cols-[40%,1fr] xl:w-full xl:grid-cols-[auto, 1fr]')
    div(class='order-1 md:row-span-2')
      a(@click='loadLightbox()')
        img(
          v-if='group.product.gallery[0].key'
          :src='getImageUrl(group.product.gallery[0].key, group.product.gallery[0].filename, 630, 472, 70, "fff")'
          :alt='group.product.gallery[0].title'
          width='630'
          height='472'
          class='m-auto md:w-full'
          loading='lazy'
        )
    div(class='order-first pb-3 md:pb-0 md:order-2')
      h2(class='uppercase mt-0 mb-3' v-html='group.title')
      div(class='flex')
        ProductPricing(
          v-if='!$sitewideConfig.config.nonTransactionalEnabled'
          :actual-price='group.product.actualPrice'
          :base-price='group.product.basePrice'
          :discount='group.product.discount'
          :is-dealer-only='group.product.isDealerOnly || isDealerOnly'
        )
        ProductReviews(
          class='pl-2'
          :total-reviews='group.product.totalReviews'
          :average-rating='group.product.averageRating'
          :show-average-rating='showAverageRating'
        )

    div(class='order-3 py-3')
      div(class='md:flex -m-3 mb-3')
        div(
          class='p-3'
          :class='group.product.hasOverriddenFeatureBullets || group.product.hasOverriddenInstallGuide || group.product.orderBullets ? "md:w-1/2" : ""'
        )
          ProductHighlights(:data='group.product' :is-group-product='true')
        div(
          v-if='group.product.hasOverriddenFeatureBullets || group.product.hasOverriddenInstallGuide || group.product.orderBullets'
          class='p-3 md:w-1/2'
        )
          //- .content is a special global class to format html tags
          div(class='content')
            p #[strong Additional Information]
            div(v-if='group.product.hasOverriddenFeatureBullets' v-html='group.product.features?.content')
            ul(v-if='group.product.orderBullets')
              li(v-for='bullet in group.product.orderBullets' :key='bullet' v-html='bullet')
            div(
              v-if='group.product.hasOverriddenInstallGuide && group.product.installation && group.product.installation.files && group.product.installation.files.length <= 2'
            )
              template(v-for='file in group.product.installation.files' :key='file.filename')
                a(v-if='file.folder' :href='getFileUrl(file.folder, file.filename)' target='_blank' class='inline-block') Download Installation Instructions

      div(data-testid='groupDrillSelection')
        ProductDrill(
          v-for='drill in group.drills'
          :key='`${drill.type + drill.groupKey}`'
          :type='drill.type'
          :group-key='drill.groupKey'
        )
      //- TODOLATER: Check on this, it would seem weird this actually exists in a group.. i don't think it does (from Nuxt 2)
      ProductMessage(v-if='group.hasOrderControlError' :show-error-icon='true')
      template(v-else-if='group.product.isDealerOnly')
        ProductDealerLocatorLink(:data='group.product')
      template(v-else)
        div(class='md:max-w-[472px]')
          div(class='space-y-2')
            ProductAddToCart(
              v-if='!$sitewideConfig.config.nonTransactionalEnabled'
              :disabled='isDisabled'
              :is-out-of-stock='group.product.isOutOfStock'
              :sku='group.product.sku'
              :qty-selector='qtySelector'
              :is-pre-order='group.product.isPreOrder'
              :group-key='group.key'
            )
            ProductBackInStockNotification(
              v-if='$sitewideConfig.config.backInStockNotificationEnabled && isIntegrationEnabled("cognito") && group.product.isOutOfStock && !group.product.isDisabled'
              :product-line-slug='group.product.productLineSlug'
              :sku-slug='group.product.skuSlug'
            )

      template(v-if='group.product.actualPrice')
        AffirmAsLowAs(
          v-if='isIntegrationEnabled("affirm")'
          :price='group.product.actualPrice.min'
          page-type='product'
          class='py-2 [&_.affirm-ala-price]:font-bold'
        )
        PaypalMessaging(
          v-if='isIntegrationEnabled("paypal")'
          :price='group.product.actualPrice.min'
          page-type='product'
          class='pt-3'
        )
</template>
<script setup lang="ts">
const { isIntegrationEnabled } = useUtils()
const ocStore = useOrderControlStore()
const { getImageUrl } = useUrls()
const { showLightbox } = usePhotoswipeLightbox()
const { $device } = useNuxtApp()
const props = defineProps<{
  groupKey: string
  qtySelector: ProductLine['qtySelector']
  isDealerOnly: ProductLine['isDealerOnly']
}>()

const group = ocStore.getGroup(props.groupKey)
const isDisabled = computed(() => {
  return !group?.product?.sku || group.product.isOutOfStock
})

function loadLightbox() {
  if (!group?.product?.gallery[0]?.key) return

  const item: PhotoswipeItem = {
    src: getImageUrl(group.product.gallery[0].key, group.product.gallery[0].filename, 1024, 768, 70, 'fff') || '',
    width: 1024,
    height: 768,
    caption: group.product.gallery[0].title,
  }
  showLightbox([item], 0)
}

const showAverageRating = computed(() => {
  return !(
    $device.value.isMobile &&
    group?.product?.discount &&
    group?.product?.actualPrice?.min != group?.product?.actualPrice?.max
  )
})
</script>
