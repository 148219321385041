export const useOrderControlStore = defineStore('OrderControl', () => {
  const log = useLogger('ORDER_CONTROL_STORE')
  const topDrills: Ref<Drill[]> = ref([])
  const topProduct: Ref<ProductLine | null> = ref(null)
  const quickProduct: Ref<ProductLine | null> = ref(null)
  const groups: Ref<Group[]> = ref([])
  const integration: Ref<OrderControlApiConfigIntegration | null> = ref(null)
  const isGoodFitment = ref(true)
  const hasSplitters = ref(false)
  const hasOrderControlError = ref(false)
  const hasMismatchedProduct = ref(false)

  let unWatchers: any = {}
  let drillConfig: OrderControlApiConfig | null = null
  let lastDrilled: DrillKey | null = null
  let slug: string | null = null
  let hadBadFitment = false
  let hadInteraction = false
  let ignoreSplitters = false
  let productPageSkuSlug = ''

  const { getApiUrl } = useUrls()
  const { $uiEvents } = useNuxtApp()
  const fitmentStore = useFitmentStore()
  const productStore = useProductStore()
  const groupKeys = computed(() => groups.value.map((group) => group.key))
  const fitmentDrillKeys = computed(() =>
    topDrills.value.filter((drill) => drill.type === 'year' || drill.type.endsWith('Slug')).map((drill) => drill.type)
  )

  const optionDrillKeys = computed(() =>
    topDrills.value
      .filter((drill) => {
        return drill.type != 'year' && !drill.type.endsWith('Slug')
      })
      .map((drill) => drill.type)
  )

  function reset() {
    // clean up watchers
    Object.keys(unWatchers).forEach((key) => unWatchers[key]())

    unWatchers = {}
    topDrills.value = []
    topProduct.value = null
    quickProduct.value = null
    groups.value = []
    isGoodFitment.value = true
    hasSplitters.value = false
    integration.value = null
    hasMismatchedProduct.value = false
    hasOrderControlError.value = false
    lastDrilled = null
    hadBadFitment = false
    hadInteraction = false
  }

  /**
   * Initializes the order controls and starts the cascading of fills
   * @param productLineSlug - the product line slug
   */
  async function init(productLineSlug: string, skuSlug = '') {
    slug = productLineSlug
    ignoreSplitters = !!skuSlug
    productPageSkuSlug = skuSlug

    reset()

    const slugBefore = slug
    const res = await fetchData()

    // deal with slow networks and people jumping around
    if (slugBefore != slug) {
      console.log(`Init stopped, slug changed ${slugBefore} -> ${slug}`)
      return
    }

    // There are no OCs and it's literally a product.  You'd think we'd at least get an empty config obj.
    // https://localhost:3000/p/rugged-ridge-black-double-r-trucker-cap/
    if (res && res.product) {
      topProduct.value = res.product
      $uiEvents.$emit('productViewed', { product: res.product })
      return
    }

    if (!res || !res.config || (!res.drill && !res.groups && !res.config.integration)) {
      hasOrderControlError.value = true
      if (res !== undefined) log.error('Init response missing or malformed.')
      return
    }

    res.config.nameMap = polyfillNameMap(res.config.nameMap)
    hasSplitters.value = !!res.config.splitAt

    if (res.config.integration) {
      integration.value = res.config.integration
      return
    }

    drillConfig = res.config

    drillConfig.drillTypes.some((drillKey: DrillKey) => {
      if (drillKey == drillConfig?.splitAt) return true
      createDrill(drillKey)
      return false
    })

    if (res.drill) fillDrill(res.drill)
    if (res.groups) fillGroups(res.groups)

    if (!res.config.splitAt) tryFillQuickProduct(skuSlug)
  }

  // takes your current fitment and tries to see if the current product/line can narrow to 1 sku.
  // This is used for a quicker "Add to Cart" button on pline pages (no waiting for order controls), and to help find product mismatches on sku pages.
  async function tryFillQuickProduct(skuSlug: string) {
    const fitmentDrillKeys = drillConfig?.drillTypes.filter((drillKey) =>
      isFitmentDrillKey(drillKey)
    ) as FitmentSlugKey[]
    const hasNotesDrill = drillConfig?.drillTypes.includes('notes')
    const hasAllFitmentDrills =
      drillConfig?.drillTypes.length === fitmentDrillKeys?.length ||
      (hasNotesDrill && drillConfig?.drillTypes.length === fitmentDrillKeys?.length + 1) // don't let the notes drill stop quick product
    const hasFitmentForAllFitmentDrills = fitmentDrillKeys?.every((drillKey) => {
      return !!fitmentStore.$state[drillKey]
    })

    if (!hasAllFitmentDrills || !hasFitmentForAllFitmentDrills) return

    const payload: DrillPostBody = {}
    fitmentDrillKeys.forEach((drillKey) => {
      payload[drillKey] = fitmentStore[drillKey]
    })

    if (hasNotesDrill) payload['notes'] = 'All Submodels'

    // try get get a product, if we guess 'All Submodels' and are wrong we'll get a 404, so let it recover
    const res = await fetchData(payload, true)
    if (!res) return

    if (res.product) {
      if (res.product.isOutOfStock) return
      quickProduct.value = res.product
      // if this is a product page, does your fitment result in the same sku you are on?
      if (skuSlug && skuSlug != quickProduct.value.skuSlug) hasMismatchedProduct.value = true
    }
  }

  function getDrillPayload(source?: DrillKey, groupKey?: string): DrillPostBody | undefined {
    const body: DrillPostBody = {}

    // build payload, go through all selected drill values, do not include the one we are trying to fetch
    if (source) {
      topDrills.value.some((drill) => {
        body[drill.type] = drill.selectedValue
        return drill.type === source || drill.type === drillConfig?.splitAt
      })

      if (groupKey) {
        if (!drillConfig || !drillConfig.splitAt) {
          hasOrderControlError.value = true
          log.error(`Got groupKey ${groupKey} but there was no split in the config.`)
          return
        }

        const group = getGroup(groupKey)
        if (!group) {
          hasOrderControlError.value = true
          log.error(`Empty result calling getGroup(${groupKey})`)
          return
        }

        body[drillConfig.splitAt] = group.title

        group.drills?.some((drill) => {
          body[drill.type] = drill.selectedValue
          return drill.type === source
        })
      }
    }

    return body
  }

  async function fetchData(payload: DrillPostBody = {}, ignoreError = false) {
    const apiUrl = getApiUrl('orderControls')
    const url = new URL(`${apiUrl}${slug}`)

    if (ignoreSplitters) url.search = 'ignoreSplitters=1'

    try {
      // TODOLATER: Report 404 errors? (Select Item Error) https://realtruck.slack.com/archives/C9C64NB7S/p1700517953551389
      const res = await $fetch<OrderControlApiResponse>(url.href, {
        method: 'POST',
        body: payload,
      })
      return res
    } catch (error: any) {
      // so we can skip logging on init, long term may want this function to return {res, error }
      if (error?.data?.errors[0]?.code === 404) return undefined

      if (!ignoreError) hasOrderControlError.value = true
      return null
    }
  }

  function resetDrillsBelow(source: DrillKey, groupKey?: string) {
    if (!drillConfig) return

    const sourceIndex = drillConfig.drillTypes.findIndex((drillKey) => drillKey === source)
    const splitIndex = drillConfig.drillTypes.findIndex((drillKey) => drillKey === drillConfig?.splitAt)
    const drillKeysToReset: DrillKey[] = []

    for (let i = sourceIndex + 1; i < drillConfig.drillTypes.length; i++) {
      // if the source was above the split don't try to reset split drills, we just kill em
      if (!groupKey && splitIndex != -1 && i >= splitIndex) break
      drillKeysToReset.push(drillConfig.drillTypes[i])
    }

    // source was above the split, kill all groups
    if (sourceIndex < splitIndex) {
      groups.value.forEach((group) => {
        if (group.drills) {
          group.drills.forEach((drill) => {
            const unWatch = unWatchers[`${drill.type}${drill.groupKey}`]
            if (unWatch) unWatch()
          })
        }
      })
      groups.value = []
    }

    drillKeysToReset.forEach((drillKey) => {
      const drill = getDrill(drillKey, groupKey)

      // nothing to reset
      if (drill?.options.length == 1) return

      if (drill) {
        // you're resetting something that already had options, don't auto select anymore
        if (drill.options.length > 1) {
          hadInteraction = true
          quickProduct.value = null
          hasMismatchedProduct.value = false
        }

        drill.selectedValue = '-1'
        drill.options.splice(1)
        drill.isDisabled = true
      }
    })
  }

  function fillGroups(groupsData: OrderControlApiGroup[]) {
    if (!drillConfig || !drillConfig.drillTypes || !drillConfig.splitAt) return

    // get drills after group
    const index = drillConfig?.drillTypes.findIndex((drillType) => drillType === drillConfig?.splitAt)
    if (index === -1) {
      hasOrderControlError.value = true
      log.error(`FillGroups could not find the split ${drillConfig?.splitAt}`)
      return
    }

    const drillsAfterSplit = drillConfig?.drillTypes.slice(index + 1)

    groupsData.forEach(({ drill, product, ...group }) => {
      const key2 = group?.image?.filename || product?.skuSlug
      const groupKey = group.title + key2 // need something besides title (when flipping between floor mat colors, 2 will have "Front Row 2 - Piece")

      groups.value.push({
        title: group.title,
        drills: [],
        product: product || getGroupDefaultProduct(group),
        key: groupKey,
      })

      drillsAfterSplit.forEach((drillKey) => {
        createDrill(drillKey, groupKey)
      })

      if (drill) fillDrill(drill, groupKey)
      if (product) $uiEvents.$emit('productViewed', { product, isGrouped: true })
    })
  }
  // It would be nice if the api gave us this temp/default product instead of us munging it together, maybe when the api is ours.
  function getGroupDefaultProduct(group: OrderControlApiGroup): GroupProduct | undefined {
    if (!productStore.data) return

    const currentProductLine = productStore.data

    return {
      gallery: [
        {
          ...group.image,
        },
      ],
      basePrice: group.basePrice,
      actualPrice: group.actualPrice,
      hasMAPViolation: currentProductLine?.hasMAPViolation,
      shipping: currentProductLine.shipping,
      totalReviews: currentProductLine?.totalReviews || 0,
      averageRating: currentProductLine?.averageRating || 0,
      isPreOrder: currentProductLine?.isPreOrder,
      discount: currentProductLine?.discount,
      promo: currentProductLine?.promo,
    }
  }

  function createDrill(drillKey: DrillKey, groupKey?: string) {
    const obj: Drill = {
      id: `drill-${drillKey}${groupKey}`.replace(/[^a-z0-9]+/gi, ''),
      type: drillKey,
      groupKey,
      selectedValue: '-1',
      isDisabled: true,
      isHidden: drillKey === 'notes',
      isFitment: isFitmentDrillKey(drillKey),
      options: [
        {
          text: `Select ${drillConfig?.nameMap[drillKey]}`,
          slug: '-1',
          isDisabled: true,
        },
      ],
    }

    if (!groupKey) topDrills.value.push(obj)
    else getGroup(groupKey)?.drills?.push(obj)

    setDrillWatcher(drillKey, groupKey)
  }

  function setDrillWatcher(drillKey: DrillKey, groupKey?: string) {
    unWatchers[drillKey + (groupKey || '')] = watch(
      () => {
        const drill = getDrill(drillKey, groupKey)
        if (!drill) {
          hasOrderControlError.value = true
          log.error(`Could not get drill ${drillKey} / ${groupKey}, slug: ${slug}`)
          return
        }
        return drill?.selectedValue
      },
      async (newVal) => {
        if (newVal === '-1') return

        const slugBefore = slug
        lastDrilled = drillKey
        const payload = getDrillPayload(drillKey, groupKey)
        if (!payload) return
        const cascadeReset = isDrillBeforeDrill(lastDrilled, drillKey)
        const res = await fetchData(payload)
        if (!res) return

        topProduct.value = null

        // something changed, and we shouldn't be cascading bad drill values, so this should be safe
        isGoodFitment.value = true

        if (cascadeReset) {
          log.info(`Cascade stopped, ${lastDrilled} changed while filling ${drillKey}`)
          return
        }

        // deal with slow networks and cascading while stuff changes
        if (slugBefore != slug) {
          log.info(`Cascade stopped, slug changed ${slugBefore} -> ${slug}`)
          return
        }

        resetDrillsBelow(drillKey, groupKey)

        if (res.drill) fillDrill(res.drill, groupKey)
        if (res.groups) fillGroups(res.groups)
        if (res.product) {
          if (groupKey) {
            const targetGroup = groups.value.find((group) => group.key == groupKey)
            if (targetGroup) {
              targetGroup.product = res.product
              $uiEvents.$emit('productViewed', { product: res.product, isGrouped: true })
            }
          } else {
            /*
              If we're on a product page and auto cascaded to a different sku, don't update the main (topProduct) info!
              It's hard to figure out if the user manually selected a drill vs auto-filing without events in components themselves which would have to report to this composable.
              Here we assume the quick product hit finishes before the whole auto-drill cascade so we can make a decent guess on if the user auto-selected something or not.
           */
            if (productPageSkuSlug && quickProduct.value && productPageSkuSlug != res.product.skuSlug) return

            topProduct.value = res.product
            $uiEvents.$emit('productViewed', { product: res.product })
          }
        }
      }
    )
  }

  function getDrill(drillKey: DrillKey, groupKey?: string) {
    let drillsToSearch: Drill[] = []

    if (!groupKey) drillsToSearch = topDrills.value
    else drillsToSearch = getGroup(groupKey)?.drills || []

    return drillsToSearch.find((d) => d.type === drillKey)
  }

  function getGroup(groupKey: string) {
    return groups.value.find((g) => g.key === groupKey)
  }

  function fillDrill(drillData: OrderControlApiDrill, groupKey?: string) {
    const drill = getDrill(drillData.type, groupKey)
    if (!drill) {
      hasOrderControlError.value = true
      log.error(`Could not find drill ${drillData.type} / ${groupKey} for slug '${slug}'`)
      return
    }

    const cleanOptions = polyfillDrillValues(drillData.type, drillData.options)

    if (drillData.type != 'year') sortObjects(cleanOptions, 'text')

    drill.groupKey = groupKey
    drill.options.splice(1, drill.options.length - 1, ...cleanOptions)
    drill.isDisabled = false

    // If we are setting drill options for make then sort it by top makes
    if (drill.type === 'makeSlug') sortTopMakes(drill.options)

    // auto select non fitment items if they have a single option
    if (drill.options.length == 2) {
      let autoSelect = ['attributedata1', 'attributedata2', 'notes'].includes(drill.type)

      // new feature added by Josh  If there is only "All Beds etc.", just select it (maybe hide it too?)
      if (
        ['bedSlug', 'bodySlug', 'engineSlug'].includes(drill.type) &&
        drill.options.length == 2 &&
        ['n-a', 'All Submodels'].includes(drill.options[1].slug)
      )
        autoSelect = true

      if (autoSelect) {
        // if you had 'All Submodels', then it got reset before this, then this sets it to 'All Submodels' again within the same cycle, the watcher won't fire
        setTimeout(() => {
          if (!drill?.options[1]?.slug) return
          drill.selectedValue = drill.options[1].slug
        }, 0)
      }
    }

    // try to auto select existing fitment (but only on initial load), and don't auto-select Bed when they had a bad Make etc..
    if (fitmentStore.isKey(drill.type) && !hadBadFitment) {
      const fitmentValue = fitmentStore[drill.type]
      const fitmentInDrill = drill.options.find((option) => option.slug === fitmentValue) !== undefined

      if (fitmentValue) {
        if (fitmentInDrill && !hadInteraction) {
          drill.selectedValue = fitmentStore[drill.type] || '-1'
        } else {
          // only set to bad fitment if bad fitment was auto selected (if they had just Make, and selected a year that didn't go with that make, don't show the Bad Fitment)
          if (!hadInteraction) isGoodFitment.value = false
          hadBadFitment = true
        }
      }
    }

    drill.isHidden = drill.type === 'notes' && drill.options.length == 2 && drill.options[1].text === 'All Submodels'
  }

  function isDrillBeforeDrill(drillOne: DrillKey, drillTwo: DrillKey) {
    if (!drillConfig) return false

    const indexOne = drillConfig.drillTypes.findIndex((drillKey) => drillKey === drillOne)
    const indexTwo = drillConfig.drillTypes.findIndex((drillKey) => drillKey === drillTwo)

    if (indexOne === -1 || indexTwo === -1) return false

    return indexOne < indexTwo
  }

  // polyfill missing name maps for makeSlug, modelSlug, etc..
  function polyfillNameMap(nameMap: Partial<Record<DrillKey, string>>) {
    const defaultLabels = {
      year: 'Year',
      makeSlug: 'Make',
      modelSlug: 'Model',
      bedSlug: 'Bed',
      bodySlug: 'Body',
      engineSlug: 'Engine',
      attributedata1: 'Attribute 1',
      attributedata2: 'Attribute 2',
      notes: 'Options',
    }
    return Object.assign({}, defaultLabels, nameMap)
  }

  // year, notes, attributedata1,2 have no 'slug' value and year comes in as a number
  function polyfillDrillValues(drillType: DrillKey, drillOptions: OrderControlApiDrillOption[]) {
    const options: DrillItem[] = drillOptions

    // convert years to strings -- Facebook/Webview issue
    for (let i = 0; i < drillOptions.length; i++) {
      drillOptions[i].text = drillOptions[i].text.toString()
    }

    // drill data was missing a slug
    if (drillOptions.length > 0 && drillOptions[0].slug == undefined) {
      options.forEach((option) => {
        if (!option.text) {
          hasOrderControlError.value = true
          log.error('Drill option missing text property')
          return
        }
        option.slug = option.text.toString()
      })
    }

    // drill n-a option was missing a nice Label
    if (options.length == 1 && options[0].slug == 'n-a') {
      let text = ''
      switch (drillType) {
        case 'bedSlug':
          text = 'All Beds'
          break
        case 'bodySlug':
          text = 'All Body Styles'
          break
        case 'engineSlug': // is this really a thing??
          text = 'All Engines'
          break
      }
      options[0].text = text
    }

    return options
  }

  function isFitmentDrillKey(drillKey: DrillKey) {
    return drillKey == 'year' || drillKey.endsWith('Slug')
  }

  return {
    reset,
    init,
    fitmentDrillKeys,
    optionDrillKeys,
    groupKeys,
    getDrill,
    getGroup,
    isGoodFitment,
    topProduct,
    hasSplitters,
    hasOrderControlError,
    quickProduct,
    hasMismatchedProduct,
    integration,
  }
})

type DrillPostBody = Partial<Record<DrillKey, string>>

interface DrillItem {
  text: string
  slug: string
  isDisabled?: boolean
}

interface Group {
  key: string
  title: string
  drills?: Drill[]
  product?: GroupProduct | ProductLine
}

// TODOLATER: Type product
interface OrderControlApiResponse {
  config?: OrderControlApiConfig
  drill?: OrderControlApiDrill
  groups?: OrderControlApiGroup[]
  product?: ProductLine
}

interface OrderControlApiConfig {
  drillTypes: DrillKey[]
  nameMap: Record<DrillKey, string>
  splitAt?: DrillKey
  integration?: OrderControlApiConfigIntegration
}

interface OrderControlApiGroup {
  actualPrice: OrderControlApiMinMax
  basePrice: OrderControlApiMinMax
  drill?: OrderControlApiDrill
  product?: ProductLine
  image: {
    filename: string
    key: string
    title: string
  }
  title: string
}

interface OrderControlApiDrill {
  options: OrderControlApiDrillOption[]
  type: DrillKey
}

interface OrderControlApiDrillOption {
  slug: string
  text: string
}
declare global {
  interface OrderControlApiConfigIntegration {
    params: object
    type:
      | 'COVERKING'
      | 'SADDLEMAN'
      | 'NORTHWEST'
      | 'TIGERTOUGH'
      | 'LLOYDS'
      | 'DASHDESIGNS'
      | 'RUFFTUFF'
      | 'AVERY'
      | 'WETOKOLE'
      | 'CALTREND'
  }

  export type DrillKey =
    | keyof Pick<Fitment, 'year' | 'makeSlug' | 'modelSlug' | 'bedSlug' | 'bodySlug' | 'engineSlug'>
    | 'attributedata1'
    | 'attributedata2'
    | 'notes'
  /*
  export type DrillKey =
    | 'year'
    | 'makeSlug'
    | 'modelSlug'
    | 'bodySlug'
    | 'bedSlug'
    | 'engineSlug'
    | 'attributedata1'
    | 'attributedata2'
    | 'notes'*/

  export type GroupProduct = Partial<
    Omit<ProductLine, 'actualPrice' | 'basePrice' | 'totalReviews' | 'averageRating' | 'gallery'>
  > &
    Pick<ProductLine, 'actualPrice' | 'basePrice' | 'totalReviews' | 'averageRating' | 'gallery'>
  interface Drill {
    type: DrillKey
    id: string
    isDisabled: boolean
    isHidden: boolean
    isFitment: boolean
    groupKey?: string
    options: DrillItem[]
    selectedValue: string
  }
}

interface OrderControlApiMinMax {
  min: number
  max: number
}
