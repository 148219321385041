<template lang="pug">
div(v-if='showPaymentRequest' id='payment-request-button')
</template>

<script setup lang="ts">
/**
 * NOTE: In order to test apple pay locally, you need to have the following configured
 *    * Apple pay needs to be setup on your computer. This will have to be your real card because test cards cannot be added to apple pay.
 *    * Stripe is running with test credentials in dev so when you checkout, stripe converts your real apple pay card into a test card and it won't be charged.
 *    * You need to run your dev env and expose it with ngrok. This will require you to be on RT's ngrok account.
 *    * Stripe requires a validated domain so you need to expose the ngrok tunnel with the rt-stripe ngrok domain.
 *    example: ngrok http https://localhost:3000 --domain=rt-stripe.ngrok.io
 */

const { sku } = defineProps<{
  sku: string | string[]
}>()

const { $stripe, $installernet, $geolocation, $uiEvents } = useNuxtApp()
const fitmentStore = useFitmentStore()
const { isIntegrationEnabled } = useUtils()
const log = useLogger('STRIPE_PAYMENT_REQUEST')

const cart = useCart('payment-request')

const showPaymentRequest = ref(false)
const isProcessing = ref(false)

onMounted(async () => {
  const stripeInstance = await $stripe.load()

  if (!stripeInstance) return

  const paymentRequest = await initPaymentRequest(stripeInstance)

  if (!paymentRequest) return

  // Display the dom node for us to attach to
  // Next tick is required to ensure the dom node is rendered
  showPaymentRequest.value = true
  await nextTick()

  // Create the payment request button
  const button = stripeInstance.elements().create('paymentRequestButton', {
    paymentRequest,
    style: {
      paymentRequestButton: {
        height: '48px',
        type: 'buy',
      },
    },
  })

  try {
    button.mount('#payment-request-button')
  } catch (error) {
    log.warning('Could not mount payment request button', { error })
  }
})

function getStripeCheckoutInfo() {
  const shippingOption = {
    id: 'free-shipping',
    label: 'Free shipping',
    amount: cart.state.value.summary.shippingSubtotal,
  }

  // If our shipping total isn't 0
  if (shippingOption.amount > 0) {
    shippingOption.id = 'standard'
    shippingOption.label = 'Standard Shipping'
  }

  return {
    total: {
      label: 'Estimated Total',
      amount: cart.state.value.summary.grandTotal,
      pending: cart.state.value.summary.grandTotal === 0,
    },
    shippingOptions: [shippingOption],
  }
}

async function initializeCart(cb: () => void) {
  // If the cart hasn't been initialized then we need to initialize it
  // and then add the item to the new cart
  if (!cart.isInitialized.value) {
    // Initialize the cart
    await cart.init()

    // Format the skus
    const skus = Array.isArray(sku) ? sku : [sku]
    const items = skus.map((localSku) => ({
      sku: localSku,
      qty: 1,
      fitmentData: fitmentStore.$state,
    }))

    // Add the items
    await cart.addItems(items)

    $uiEvents.$emit('productCheckoutStarted', cart.state.value.items)

    // if we have installernet and geolocation, then we need to see if we need to add installation to the skus
    if (isIntegrationEnabled('installernet') && isIntegrationEnabled('geolocation')) {
      const itemsForInstallation = cart.state.value.items.map((item) => ({
        id: item.id,
        // Eventhough the getQuoteIdBySku says it returns a string, it could potentially be undefined so i'm just
        // showing that here which is why the fallback for undefined is here.
        quoteId: $installernet.getQuoteIdBySku(item.sku) || undefined,
      }))

      for (let i = 0; i < itemsForInstallation.length; i++) {
        const item = itemsForInstallation[i]

        if (item.quoteId)
          await cart.addItemInstallation(item.id, {
            quoteId: item.quoteId,
            city: $geolocation.city,
            state: $geolocation.region,
          })
      }
    }
  }

  // Trigger the callback
  cb()
}

async function initPaymentRequest(stripeInstance: any) {
  const paymentRequest = stripeInstance.paymentRequest({
    country: 'US',
    currency: 'usd',
    requestPayerName: true,
    requestPayerEmail: true,
    requestPayerPhone: true,
    requestShipping: true,
    // Note: We only support apple pay right now. I don't have a good way to test google pay
    disableWallets: ['googlePay', 'link', 'browserCard'],
    ...getStripeCheckoutInfo(),
  })

  // Check if can make payment
  const result = await paymentRequest.canMakePayment()

  // Only do the rest of the stuff if we have a result
  if (!result) return

  paymentRequest.on('shippingaddresschange', function (event) {
    initializeCart(() => {
      event.updateWith({
        status: 'success',
        ...getStripeCheckoutInfo(),
      })
    })
  })

  paymentRequest.on('cancel', function () {
    // if we are processing and we got this event then we don't need to do anything.
    // Note that in some browsers, the payment interface may be dismissed by the customer
    // even after they authorize the payment. This means that we may receive a cancel event
    // after receiving a token.
    if (isProcessing.value) return

    // If we cancel then we can delete the cart
    cart.deleteCart()
  })

  // Add token event
  paymentRequest.on('token', async (event) => {
    isProcessing.value = true

    try {
      await cart.setContact({
        shipping: {
          fullName: event.shippingAddress.recipient,
          email: event.payerEmail,
          address1: event.shippingAddress.addressLine[0],
          address2: event.shippingAddress.addressLine[1] || '',
          city: event.shippingAddress.city,
          stateId: event.shippingAddress.region,
          zipcode: event.shippingAddress.postalCode,
          country: 'US',
          phone: event.shippingAddress.phone,
        },
        billing: {
          fullName: event.token.card.name,
          email: event.payerEmail,
          address1: event.token.card.address_line1,
          address2: event.token.card.address_line2 || '',
          city: event.token.card.address_city,
          stateId: event.token.card.address_state,
          zipcode: event.token.card.address_zip,
          country: 'US',
          phone: event.payerPhone,
        },
      })

      await cart.processPayment({
        type: 'STRIPE_PAYMENT_REQUEST',
        raw: event,
        token: event.token.id,
      })

      // Complete the payment event
      event.complete('success')
    } catch (error) {
      event.complete('fail')
    } finally {
      isProcessing.value = false
    }
  })

  return paymentRequest
}
</script>
