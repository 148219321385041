<template lang="pug">
#tabs(ref='container' class='mt-3')
  div(class='overflow-hidden h-16')
    #tabHeader(ref='header' class='tabs-header bg-gray-lighter border-none')
      ul(class='list flex')
        li(
          v-for='tab in tabsArray'
          :id='`tab-${tab.id}`'
          :key='tab.id'
          class='p-3 mr-3 cursor-pointer text-center'
          @click='selectTab(tab.id)'
        )
          div(class='text-sm text-dark uppercase font-bold xs:text-base hover:no-underline') {{ tab.name }}

  div(class='content pt-4 pb-6')
    ProductTabsTab(
      v-for='(tab, index) in tabsArray'
      :id='tab.id'
      :key='tab.id'
      :name='tab.name'
      :selected='alwaysShowTabs || index === getSelectedTabIndex'
      :class='tab.class'
    )
      slot(:name='tab.id')
</template>

<script setup lang="ts">
const { $scrollTo } = useNuxtApp()
const { tabsArray } = defineProps<{
  tabsArray: {
    name: string
    id: string
    class?: string
  }[]
}>()

const container = ref<HTMLElement | null>(null)
const header = ref<HTMLElement | null>(null)
const getSelectedTabIndex = ref(0)
const alwaysShowTabs = ref(true)
const supressDetect = ref(false)
const scrollPosTopPadding = ref(50)

onMounted(() => {
  nextTick(() => {
    window.addEventListener('scroll', handleScroll)
  })
})
onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
})

// Central catch for scroll events
function handleScroll() {
  // Disabled when we know where we are going
  if (supressDetect.value) return
  detectSection()
}

function selectTab(tabId: string, thenScroll = true) {
  // Scroll to the section selected by default
  // We don't want to scroll if we are Detecting the tab based on user scroll
  if (typeof thenScroll === 'undefined') thenScroll = true
  if (thenScroll) scrollToSection(tabId)
}

// Detect if the user has scrolled into a new tab section then move the underline marker
function detectSection() {
  tabsArray.forEach((tab) => {
    const el = document.getElementById(`scrollTarget-${tab.id}`)
    if (!el) return
    const top = el.getBoundingClientRect().top + window.scrollY - scrollPosTopPadding.value
    if (window.scrollY > top && window.scrollY < top + el.offsetHeight) selectTab(tab.id, false)
  })
}

// Scroll down to a section when a user clicks a corresponding tab
function scrollToSection(tabId: string) {
  nextTick(() => {
    $scrollTo(`#scrollTarget-${tabId}`, 500, {
      offset: -Math.abs(scrollPosTopPadding.value),
      onStart: () => {
        // Don't bother with Detect methods on scroll, save the effort since we know what needs to happen.
        supressDetect.value = true
      },
      onCancel: () => {
        // Turn detection back on if they interrupt the auto scroll
        supressDetect.value = false
      },
      onDone: () => {
        // Turn detection back on when we are done
        supressDetect.value = false
      },
    })
  })
}
</script>
