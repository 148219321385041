<template lang="pug">
div(v-if='totalReviews > 0' class='flex relative font-bold')
  div(v-if='$device.isSmall' class='z-10 absolute top-0 bottom-0 right-0 left-0 cursor-pointer' @click='$overlay.open("reviews")')
  a(
    v-else
    v-scroll-to='"#scrollTarget-reviews"'
    href='#'
    aria-label='Show Reviews'
    class='z-10 absolute top-0 bottom-0 right-0 left-0 cursor-pointer'
  )
  Stars(:average-rating='averageRating' :total-reviews='totalReviews' :show-average-rating='showAverageRating')
</template>

<script setup lang="ts">
const { $overlay } = useNuxtApp()

const { totalReviews, averageRating } = defineProps<{
  totalReviews: ProductLine['totalReviews']
  averageRating: ProductLine['averageRating']
  showAverageRating: ProductLine['showAverageRating']
}>()
const { $device } = useNuxtApp()
</script>
