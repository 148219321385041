<template lang="pug">
div(class='vehicle-fit flex items-center' :class='classObject')
  div(v-if='type !== "more" && type !== "select"')
    div(class='fitIcon relative rounded-full p-5 w-8 h-8 md:p-3')
      XIcon(v-if='type === "bad"' class='icon absolute m-auto inset-0 fill-current text-white')
      ExclamationPoint(v-else-if='type === "mismatch"' class='icon absolute m-auto inset-0 fill-current text-white')
      PhoneIcon(
        v-else-if='type === "good" && $sitewideConfig.config.guaranteedFitEnabled && guaranteedFit === "PHONE"'
        alt='phone'
        class='icon absolute m-auto inset-0 w-5 fill-white'
      )
      img(v-else-if='type === "good"' src='/images/checkmarkThick.svg' alt='checkmark' class='icon absolute m-auto inset-0')

  div(class='w-full' :class='type !== "more" && type !== "select" ? "pl-4" : ""')
    div
      div(v-if='type === "select" || type === "more"')
        span(class='block font-bold') Select Your Vehicle
      span(v-if='type === "bad" || type === "mismatch"' class='font-bold') Does Not Fit Your Vehicle

      FitmentGuaranteedFit(
        v-if='type === "good" && $sitewideConfig.config.guaranteedFitEnabled && guaranteedFit'
        :type='guaranteedFit'
      )
      span(v-else-if='type === "good"' class='block font-bold') Fits Your Vehicle

      div(v-if='type === "bad" || type === "good"' data-testid='filteredFitment')
        span {{ fitmentStore.fitmentFormatted }}
        span(v-if='fitmentStore.fitmentFormattedSecondary') , {{ fitmentStore.fitmentFormattedSecondary }}

    div(class='flex items-center')
      div(v-if='type !== "more" && type !== "select"' class='mt-1 md:mt-0')
        a(class='inline-block text-black underline md:self-center md:text-right' @click='$pageBus.$emit("changeVehicle")') Change vehicle
      div(v-if='type === "bad"')
        span(class='px-1') |
        UniversalLink(:link='mmyParams' class='inline-block text-black underline') See products that fit
</template>

<script setup lang="ts">
import XIcon from '@/assets/x.svg?component'
import ExclamationPoint from '@/assets/exclamationPoint.svg?component'
import PhoneIcon from '@/assets/phone.svg?component'

const fitmentStore = useFitmentStore()
const { $uiEvents, $sitewideConfig } = useNuxtApp()
const route = useRoute()
const { type = 'good', guaranteedFit } = defineProps<{
  type: 'good' | 'bad' | 'more' | 'mismatch' | 'select'
  guaranteedFit?: 'SITE' | 'PHONE'
}>()

const $pageBus: any = inject('pageBus')

const classObject = computed(() => {
  return {
    '-fitsGood [&_.fitIcon]:bg-success [&_.icon]:w-5': type === 'good',
    '-fitsBad [&_.fitIcon]:bg-danger [&_.icon]:w-4': type === 'bad',
    '-fitsMore [&_.fitIcon]:bg-gray-dark [&_.icon]:w-4': type === 'more',
    '-fitsMismatch [&_.fitIcon]:bg-warning-dark [&_.icon]:h-5 [&_.icon]:left-[2px]': type === 'mismatch',
    '-fitsSelect [&_.fitIcon]:bg-gray-dark [&_.icon]:w-4': type === 'select',
  }
})

const mmyParams = computed(() => {
  const mmy = ['', 'v']
  if (fitmentStore.makeSlug) mmy.push(fitmentStore.makeSlug)
  if (fitmentStore.modelSlug) mmy.push(fitmentStore.modelSlug)
  if (fitmentStore.year) mmy.push(fitmentStore.year)
  return mmy.join('/')
})

onMounted(() => {
  if (type === 'bad') $uiEvents.$emit('doesNotFitViewed', route.fullPath)
})
</script>
