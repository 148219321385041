<template lang="pug">
div(class='h-full')
  div(class='sticky top-4 max-w-4xl m-auto')
    div
      div
        Carousel(:scroll-to='currentSlide' :arrow-mode='$device.isMobile ? "ALWAYS" : "NONE"' arrow-style='THIN')
          template(
            v-for='(image, index) in gallery'
            :key='`main-${image.filename}-${index}-${imageOptions.width}-${imageOptions.height}`'
          )
            div(class='w-full cursor-pointer' @click='loadLightbox(index)')
              GalleryImage(
                v-if='image.key'
                :src='getImageUrl(image.key, image.filename, imageOptions.width, imageOptions.height, 80, "fff") || ""'
                :width='imageOptions.width'
                :height='imageOptions.height'
                :alt='image.title'
                :is-lazy='index > 0'
                class='mx-auto'
              )

      div(v-if='showThumbs && gallery.length > 1' class='mt-4 mx-auto hover:cursor-pointer')
        Carousel(container-class='gap-x-1 mb-2')
          div(
            v-for='(image, index) in gallery'
            :key='`thumb-${image.key}-${index}-${image.filename}`'
            class='border-2 border-solid w-[140px] rounded'
            :class='[activeThumb === index ? "border-black" : "border-gray-light"]'
            @click='activateThumb(index)'
          )
            GalleryImage(
              v-if='image.key'
              :src='getImageUrl(image.key, image.filename, 140, 105, 70) || ""'
              :width='140'
              :height='105'
              :is-lazy='index > ($device.isMobile ? 2 : 5)'
            )

      div
        slot(name='footer')
</template>
<script setup lang="ts">
const { $device } = useNuxtApp()
const { getImageUrl } = useUrls()
const { showLightbox } = usePhotoswipeLightbox()
const currentSlide = ref(0)
const activeThumb = ref(0)
const { gallery, showThumbs = true } = defineProps<{
  gallery: ProductLine['gallery']
  showThumbs: boolean
}>()

const imageOptions = computed(() => {
  const width = $device.value.isMobile ? 390 : 860
  const height = $device.value.isMobile ? 293 : 650

  return { width, height }
})

function loadLightbox(index: number) {
  const items: PhotoswipeItem[] = []

  gallery.forEach((image) => {
    if (!image.key) return

    items.push({
      src: getImageUrl(image.key, image.filename, 1024, 768, 80, 'fff') || '',
      width: 1024,
      height: 768,
      caption: image.title,
    })

    showLightbox(items, index)
  })
}

function activateThumb(index: number) {
  currentSlide.value = index
  activeThumb.value = index
}
</script>
