<template lang="pug">
div
  div(
    v-if='data'
    data-cnstrc-product-detail
    :data-cnstrc-item-id='data.productLineSlug'
    :data-cnstrc-item-variation-id='data.skuSlug ? `${data.productLineSlug}_${data.skuSlug}` : undefined'
    :data-cnstrc-item-name='data.productLineName'
    :data-cnstrc-item-price='data.actualPrice?.min'
    class='p-3 md:bg-white'
  )
    Crumbs(:crumbs='data.crumbs')
    div(class='lg:container mt-4')
      div(class='grid md:grid-rows-[auto,1fr] md:grid-cols-[60%,1fr] xl:grid-cols-[auto,1fr] gap-x-6')
        div(class='order-1 py-3 md:row-span-2 xl:w-[860px]')
          Gallery(:gallery='data.gallery' :show-thumbs='!$device.isMobile')
            template(#footer)
              div(class='flex flex-col cursor-pointer mt-3 lg:flex-row lg:mt-0')
                ProtexWrapper(:product-line-slug='data.productLineSlug')
                  UniversalLink(
                    link='PROTEX'
                    element='button'
                    class='flex items-center justify-center font-bold py-2 px-6 mb-2 lg:mr-2 lg:mb-0 border rounded text-inherit'
                  )
                    img(src='/images/3dSpin.svg' alt='' class='w-6 h-4 mr-2')
                    | See it on your F-150
                div(
                  v-if='hasCustomerPhotos'
                  class='flex items-center justify-center font-bold py-2 px-6 border rounded'
                  @click='showCustomerPhotos'
                )
                  img(src='/images/customPhoto.svg' alt='' class='w-[18px] h-3.5 mr-2')
                  | Customer Photos

              div(v-show='$device.isMobile && showFinancing' class='pt-2')
                AffirmAsLowAs(
                  v-if='isIntegrationEnabled("affirm") && data.actualPrice'
                  :price='data.actualPrice.min'
                  page-type='product'
                  class='[&_.affirm-modal-trigger]:text-inherit [&_.affirm-modal-trigger]:text-small [&_.affirm-modal-trigger]:underline'
                )
                PaypalMessaging(
                  v-if='isIntegrationEnabled("paypal") && data.actualPrice'
                  :price='data.actualPrice.min'
                  page-type='product'
                  :is-grayscale='true'
                  class='pt-3'
                )

          //- NOT SURE WE EVEN USE THIS ANYMORE div(v-if='data.brand && data.brand.image' class='font-bold text-black text-center')
            | AUTHORIZED DEALER:
            img(:src='data.brand.image.url' :alt='data.brand.image.title' class='align-middle')
        div(class='order-first md:order-2 pt-3')
          span(
            v-if='isRealtruckBrand(data.brand.name) && data.name && !data.name.toLowerCase().startsWith("realtruck")'
            class='or-product-title-brand mb-3 mt-0 text-4xl text-black font-extrabold uppercase font-brand leading-6'
          )
            | RealTruck
            |
          h1(
            data-testid='productTitle'
            class='or-product-title mb-3 mt-0 text-4xl text-black inline uppercase font-brand leading-6'
          )
            InlineHtml(:text='data.name')
          br
          div(class='flex items-center justify-between pt-2')
            ProductPricing(
              v-if='!$sitewideConfig.config.nonTransactionalEnabled'
              :actual-price='data.actualPrice'
              :base-price='data.basePrice'
              :discount='data.discount'
              :has-m-a-p-violation='data.hasMAPViolation'
              :is-out-of-stock='data.isOutOfStock'
              :is-dealer-only='data.isDealerOnly'
            )
            ProductReviews(
              :average-rating='data.averageRating'
              :total-reviews='data.totalReviews'
              :show-average-rating='showAverageRating'
            )

          div(v-show='!$device.isMobile && showFinancing' class='pt-8')
            AffirmAsLowAs(
              v-if='isIntegrationEnabled("affirm") && data.actualPrice'
              :price='data.actualPrice.min'
              page-type='product'
              class='[&_.affirm-modal-trigger]:text-inherit [&_.affirm-modal-trigger]:text-small [&_.affirm-modal-trigger]:underline'
            )
            PaypalMessaging(
              v-if='isIntegrationEnabled("paypal") && data.actualPrice'
              :price='data.actualPrice?.min'
              page-type='product'
              :is-grayscale='true'
              class='pt-3'
            )

        div(class='order-3')
          ProductInfo(:data='data')
          ProductPromoImages(v-if='data.promoImages')
          ClientOnly
            ProductSmallGroups(v-if='showRightSplitters && ocStore.groupKeys.length > 0' :data='data')
      ClientOnly
        div(v-if='ocStore.groupKeys.length > 0 && !showRightSplitters' id='multiproducts-area' data-testid='productGroups')
          ProductGroup(
            v-for='(groupKey, index) in ocStore.groupKeys'
            :key='groupKey'
            :group-key='groupKey'
            :array-index='index'
            :qty-selector='data.qtySelector'
            :is-dealer-only='data.isDealerOnly'
          )

        div(v-else-if='ocStore.integration' id='multiproducts-area' ref='groups' data-testid='productGroups')
          LazyProductThirdParty(:integration='ocStore.integration')

      ConstructorABTest
        template(#original)
          //- RELATED/RECOMMENDED PRODUCTS
          CarouselProduct(
            v-if='hasRelatedProducts && $sitewideConfig.config.recommendedProductsEnabled'
            product-type='related'
          )
          CarouselProduct(v-else-if='$sitewideConfig.config.recommendedProductsEnabled' product-type='recommended')

        template(#constructor)
          ConstructorRecommendations(pod-id='product-page-1' :item-id='data.productLineSlug')

  div(v-if='data' class='p-3 md:bg-white')
    div(class='lg:container mt-4')
      ProductExtraInfo(:has-customer-photos='hasCustomerPhotos' :data='data')

      ConstructorABTest
        template(#original)
          //- RECOMMENDED/SIMILAR PRODUCTS
          CarouselProduct(
            v-if='hasRelatedProducts && $sitewideConfig.config.recommendedProductsEnabled'
            product-type='recommended'
          )
          CarouselProduct(
            v-if='$sitewideConfig.config.recommendedProductsEnabled'
            id='carousel-similar-bottom'
            product-type='similar'
          )
          //- RECENT PRODUCTS
          CarouselProduct(product-type='recent')

        template(#constructor)
          ConstructorRecommendations(pod-id='product-page-2' :item-id='data.productLineSlug')
          ConstructorRecommendations(pod-id='product-page-3' :item-id='data.productLineSlug')
          ConstructorRecommendations(pod-id='product-page-4' :item-id='data.productLineSlug')
</template>
<script setup lang="ts">
/**
 * Order Notes: /p/300-industries-led-grilles/
 * Scroll: /p/retraxpro-xr-tonneau-cover/#scrollTarget-install
 */
import mitt from 'mitt'
const route = useRoute()
const { $device, $scrollTo, $sitewideConfig, $yotpo, $overlay, $speedcurve } = useNuxtApp()
const { isIntegrationEnabled, isRealtruckBrand } = useUtils()
const { getImageUrl } = useUrls()
const { resolveTemplates } = useMetaTagTemplates()
const { translateTemplate } = useTemplateTranslator()
const productLineSlug = route.params.productLine.toString()
const skuSlug = route.params.skuSlug?.toString()
const isProductPage = computed(() => !!route.params.skuSlug)
const ocStore = useOrderControlStore()
const productStore = useProductStore()
const fitmentStore = useFitmentStore()
const recStore = useRecommendationsStore()
const hasCustomerPhotos = ref(false)
const emitter = mitt()

try {
  await productStore.init(productLineSlug, skuSlug)
} catch (error) {
  if (error?.statusCode === 404) throw createError({ statusCode: 404, fatal: true })
  throw createError({ statusCode: 500, message: error?.message, fatal: true })
}

ocStore.reset()

const data = computed(() => ocStore.topProduct || productStore.data)
const showRightSplitters = ref(false)

onMounted(() => {
  showRightSplitters.value =
    $sitewideConfig.config.rightAlignedSplitterEnabled && data.value?.brand?.name === 'Husky Liners'
})

provide('showRightSplitters', showRightSplitters)

const showFinancing = computed(() => {
  return (
    !ocStore.hasOrderControlError &&
    !data.value.isDealerOnly &&
    !ocStore.hasSplitters &&
    data.value.actualPrice &&
    data.value.actualPrice.min
  )
})

provide('pageBus', {
  $on: emitter.on,
  $off: emitter.off,
  $emit: emitter.emit,
})

// if you're on a sku page, with a sku that fits and drill to a new sku, only update the url (don't reload the whole page)
watch(
  () => ocStore.topProduct?.skuSlug,
  (newSkuSlug) => {
    if (!isProductPage.value || ocStore.hasMismatchedProduct || !newSkuSlug || route.params.skuSlug === newSkuSlug)
      return

    const newUrl = route.fullPath.replace(route.params.skuSlug.toString(), newSkuSlug)
    history.replaceState(null, '', newUrl)
  }
)

onBeforeMount(() => {
  $speedcurve.track(skuSlug ? 'Product Page' : 'Product Line Page')
})

onMounted(async () => {
  await ocStore.init(productLineSlug, skuSlug)

  if (isIntegrationEnabled('yotpo') && data.value) {
    hasCustomerPhotos.value = await $yotpo.hasCustomerPhotos(data.value.yotpoId)
  }
})

definePageMeta({
  // don't re-init the whole page when we just update fitment in the url
  key(route) {
    const skuSlug = (route.params.skuSlug as string) || ''
    const productLineSlug = (route.params.productLine as string) || ''
    const z = route.query.z || ''

    return productLineSlug + skuSlug + z
  },

  validate: (route) => {
    return isValidUrl(route.path)
  },
})

if (data.value) {
  useServerHead({
    script: [{ type: 'application/ld+json', innerHTML: getSchema(data.value), tagPosition: 'bodyClose' }],
  })
}

const metaTemplates = resolveTemplates(isProductPage.value ? 'product' : 'productLine')
const metaVariables = {
  ...(isProductPage.value && { PRODUCT: data.value?.name }),
  ...(isProductPage.value && { SKU: data.value?.mpn || data.value?.sku }),
  ...(!isProductPage.value && { PRODUCT_LINE: data.value?.name }), // it's really weird we don't just have productLineName from the API vs a 'name' field that changes
  BRAND: data.value?.brand.name || '',
}

const socialImageUrl = data.value
  ? getImageUrl(data.value.gallery[0].key, data.value.gallery[0].filename, 800, 600, 80, 'fff')
  : ''

const title = computed(() => translateTemplate(metaTemplates.title, metaVariables))
const description = translateTemplate(metaTemplates.description, metaVariables)
useMetaTags({
  title,
  description,
  isNoIndex: !isProductPage.value && !!fitmentStore.makeSlug,
  ...(socialImageUrl && { socialImageUrl }),
})

const hasRelatedProducts = computed(() => !!recStore.state.related)

const showAverageRating = computed(() => {
  return !(
    $device.value.isMobile &&
    data.value?.discount &&
    data.value?.actualPrice?.min != data.value.actualPrice?.max
  )
})

function showCustomerPhotos() {
  if ($device.value.isDesktop) {
    const element = document.getElementById('scrollTarget-photos')
    if (element) $scrollTo(element)
  } else {
    $overlay.open('photos')
  }
}

function webPageSchema() {
  return {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    url: `https://${$sitewideConfig.domain}${route.path}`,
    headline: title,
    description,
    publisher: {
      '@type': 'Organization',
      name: $sitewideConfig.config.siteName,
    },
  }
}

useServerHead({
  script: [{ type: 'application/ld+json', innerHTML: webPageSchema(), tagPosition: 'bodyClose' }],
})

function getSchema(data: ProductLine) {
  const obj: any = {
    '@context': 'http://schema.org',
    '@type': 'Product',
    name: data.name,
  }

  if (data.gallery[0]) obj.image = getImageUrl(data.gallery[0].key, data.gallery[0].filename)

  if (data.totalReviews > 0) {
    obj.aggregateRating = {
      '@type': 'AggregateRating',
      ratingValue: data.averageRating,
      reviewCount: data.totalReviews,
    }
  }

  if (data.brand && data.brand.name) {
    obj.brand = {
      '@type': 'brand',
      name: data.brand.name,
      // "logo": ?
    }
  }

  if (!isProductPage.value) {
    if (!data.hasMAPViolation) {
      if (data.actualPrice && data.actualPrice?.min !== data.actualPrice?.max)
        obj.offers = {
          '@type': 'AggregateOffer',
          lowPrice: data.actualPrice.min / 100,
          highPrice: data.actualPrice.max / 100,
          priceCurrency: 'USD',
          itemCondition: 'NewCondition',
        }
      else if (data.actualPrice) {
        obj.offers = {
          '@type': 'Offer',
          price: data.actualPrice.min / 100,
          priceCurrency: 'USD',
          itemCondition: 'NewCondition',
        }
      }
    }
  } else {
    if (data.actualPrice) {
      obj.offers = {
        '@type': 'Offer',
        price: data.actualPrice.min / 100,
        priceCurrency: 'USD',
        itemCondition: 'NewCondition',
      }
    }
    obj.mpn = data.mpn || data.sku
    obj.sku = data.sku

    obj.url = `https://${$sitewideConfig.domain}${useRoute().path}`
  }

  if (obj.offers)
    obj.offers.availability = data.isOutOfStock ? 'http://schema.org/OutOfStock' : 'http://schema.org/InStock'

  return JSON.stringify(obj)
}
</script>
